import Alpine from "alpinejs"
import focus from "@alpinejs/focus"
import ui from "@alpinejs/ui"

Alpine.plugin(focus)
Alpine.plugin(ui)

window.Alpine = Alpine

Alpine.start()
